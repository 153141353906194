<template>
  <div class="w-full flex">
    <div
      v-for="(item, index) in option"
      :key="index"
      class="text-c18 text-90 font-c5 pl-5 pr-5 pb-3 cursor-pointer"
      :class="{'item': position === item}"
      @click="selected(item)"
    >
      {{ item }}
    </div>
  </div>
</template>

<script>

export default {
  name: "Index",
  props: {
      option: {
        type: Array,
        default: null
      },
    value: {
      type: String,
      default: ''
    },
  },

  data () {
    return {
      position: null
    }
  },

  created () {
    this.position = this.value
  },

  methods: {
    selected(item){
      this.position = item
      this.$emit('info', item)
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables";
  .item {
    border-bottom: 3px solid $oho-blue-clear;
    color: $oho-blue-clear
  }
</style>
